<template>
    <div class="flex gap-3">
        <Avatar size="10" :user="comment?.user" :preview="true" />
        <div class="grid flex-auto">
            <div class="flex items-center justify-between gap-1">
                {{}}
                <h6 class="text-xs font-semibold text-primary">
                    {{ full_name ?? "" }}
                </h6>
                <span class="text-xs font-semibold text-disabled">{{
                    formattedDate(comment?.updated_at)
                }}</span>
            </div>
            <p class="text-sm text-secondary" v-html="comment?.content"></p>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import Avatar from "@/Components/Avatar.vue";
import moment from "moment";

const props = defineProps({
    comment: Object,
    user: Object,
});

const formattedDate = computed(() => {
    return function (date) {
        return moment(date).fromNow();
    };
});
const full_name = computed(() => {
    return props?.comment?.user.fullname;
});
</script>
<style>
.mention-link {
    color: rgba(5, 22, 178, 0.5);
    cursor: pointer;
    text-decoration: underline;
}
</style>
